<template>
  <b-card
      class="owner-create-wrapper"
  >
    <p><strong>CLIENT BENEFICIAL OWNER PROFILE:</strong> This is where details about a secondary beneficial owner for the <strong>client business</strong> are entered.</p>
    <!--form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <h3>General Information</h3>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="First Name"
              label-for="owner-first-name"
              class="mb-2"
          >
            <b-form-input
                id="owner-first-name"
                v-model="owner.firstName"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Last Name"
              label-for="owner-last-name"
              class="mb-2"
          >
            <b-form-input
                id="owner-last-name"
                v-model="owner.lastName"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
            <b-form-group label="Boom #" label-for="boom-number" class="mb-2">
                <b-form-input id="boom-number" v-model="owner.boomNumber"/>
            </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Title"
              label-for="owner-title"
              class="mb-2"
          >
            <b-form-input
                id="owner-title"
                v-model="owner.title"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="What is the % holding of this Individual in the clients business?"
              label-for="owner-holding-percent"
              class="mb-2"
          >
            <cleave
                id="owner-holding-percent"
                v-model="owner.holdingPercent"
                @blur="saveOwnerProgress"
                class="form-control"
                :raw="false"
                :options="options.number"
                placeholder="100"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <strong>Select all the relevant attributes for this individual from the list below</strong>
          <b-form-group>
            <b-form-checkbox
                v-model="owner.attributes"
                id="sole-practitioner"
                name="owner_attributes"
                value="Sole practitioner"
                @change="saveOwnerProgress"
            >
              Sole practitioner
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.attributes"
                id="controls-practice"
                name="owner_attributes"
                value="Owns or exercises ultimate control of the practice"
                @change="saveOwnerProgress"
            >
              Owns or exercises ultimate control of the practice
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.attributes"
                id="holds-voting-rights"
                name="owner_attributes"
                value="Holds >25% shares or voting rights/capital or profits or voting rights"
                @change="saveOwnerProgress"
            >
              Company Director or Secretary Holds >25% shares or voting rights/capital or profits or voting rights
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.attributes"
                id="partner-member"
                name="owner_attributes"
                value="Partner member of LLP"
                @change="saveOwnerProgress"
            >
              Partner member of LLP
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <risk-question-block :system-form-id="7" :form="form" :answers="owner.answers"
                           v-on:updateProgressReturn="updateProgressListener"
                           v-on:updateRiskReturn="updateRiskListener" v-on:getAnswersReturn="getAnswersListener"
                           v-on:updateFilesReturn="updateFilesListener"></risk-question-block>

      <b-row>
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="saveOwner(false)"
          >
            <span v-if="!savingOwner">Save Changes</span>
            <span v-if="savingOwner"><b-spinner small/> Saving...</span>
          </b-button>
          <b-button
              :disabled="!canComplete || completed"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="!completed ? 'success' : 'flat-success'"
              class="mr-1"
              @click="saveOwner(true)"
          >
            <span class="align-middle" v-if="!completed && !savingOwner">Complete</span>
            <span class="align-middle" v-if="completed &&  !savingOwner"><feather-icon
                icon="CheckIcon"/> Completed</span>
            <span v-if="savingOwner"><b-spinner small/> Saving...</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--form-->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.gb'
import clientSecondaryOwnerService from "@core/services/client-secondary-owner/useClientSecondaryOwner";
import practiceService from "@core/services/practice/usePractice";
import riskService from "@core/services/risk/useRisk";
import clientService from "@core/services/client/useClient";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import RiskQuestionBlock from "../../blocks/RiskQuestionBlock";
import {$themeConfig} from "@themeConfig";
import clientOwnerService from "@core/services/client-owner/useClientOwner";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormFile,
    BInputGroupPrepend,
    BFormCheckbox,
    BSpinner,
    BOverlay,
    RiskQuestionBlock,
    VBTooltip,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mounted() {
    if (this.$route.params.id) {
      this.owner.id = this.$route.params.id;
    }
  },
  data() {
    return {
      owner: {
        id: 0,
        firstName: "",
        lastName: "",
        title: "",
        attributes: [],
        holdingPercent: 0,
        boomNumber: "",
        lastUpdated: "",
        answers: []
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      form: {},
      riskQuestions: [],
      structureTypes: ['Sole Trader', 'Partnership', 'Ltd Company', 'Limited Partnership', 'Limited Liability Partnership', 'Other'],
      yesNoUncertain10: [{label: 'Yes', risk: 0}, {label: 'No', risk: 10}, {label: 'Uncertain', risk: 10}],
      yesNoUncertain5: [{label: 'Yes', risk: 0}, {label: 'No', risk: 5}, {label: 'Uncertain', risk: 5}],
      yesNoUncertain: ['Yes', 'No', 'Uncertain'],
      yesNo: ['Yes', 'No'],
      services: ['Accounts Preparation', 'Corporation Tax', 'Budgets', 'CIS (Construction Industry Scheme)', 'Cash Flow Forecast', 'Management Accounts', 'Payroll and Auto Enrolment Services', 'Income Tax Self-Assessment', 'Tax Advice / Planning', 'VAT Submission'],
      ownerRisk: 0,
      canComplete: false,
      completed: false,
      savingOwner: false,
      pageLoaded: false
    }
  },
  methods: {
    getAnswersListener(value) {
      this.riskQuestions = value;
      this.getOwner();
    },
    getOwner() {
      if (this.owner.id) {
        clientSecondaryOwnerService.getClientSecondaryOwner({
          id: this.owner.id
        }).then(response => {
          this.owner = response.data;
          this.canComplete = this.owner.isComplete;
          this.completed = this.owner.completed;
          this.ownerRisk = this.owner.risk;
          this.pageLoaded = true;
        }).catch(error => {
          if (error.response.status == 403) {
            window.location = $themeConfig.app.suspendedAccountRedirect;
          }
        });
      }else{
        this.pageLoaded = true;
      }
    },
    saveOwnerProgress() {
      this.owner.holdingPercent = parseInt(this.owner.holdingPercent.replace(/,/g, ''));
      if (this.owner.holdingPercent > 100) {
        this.owner.holdingPercent = 100;
      }
      if (this.owner.holdingPercent < 0) {
        this.owner.holdingPercent = 0;
      }
      if (this.owner.id) {
        clientSecondaryOwnerService.updateClientSecondaryOwner({
          clientId: this.$route.query.clientId,
          owner: this.owner,
          complete: false,
          id: this.owner.id
        }).then(response => {
          this.canComplete = response.data.complete;
        }).catch(error => {
          if(error.response.status == 403){
            window.location = $themeConfig.app.suspendedAccountRedirect;
          }
        });
      } else {
        clientSecondaryOwnerService.createClientSecondaryOwner({
          clientId: this.$route.query.clientId,
          owner: this.owner,
          complete: false,
        }).then(response => {
          if (response.data.id) {
            this.owner.id = response.data.id;
          }
        }).catch(error => {
          if(error.response.status == 403){
            window.location = $themeConfig.app.suspendedAccountRedirect;
          }
        });
      }
    },
    saveOwner(complete) {
      this.savingOwner = true;
      if (this.owner.id) {
        clientSecondaryOwnerService.updateClientSecondaryOwner({
          clientId: this.$route.query.clientId,
          owner: this.owner,
          id: this.owner.id
        }).then(response => {
          if (response.data.errors) {
            this.showErrorMessage(response.data.errors);
          } else {
            this.showSuccessMessage("You have successfully updated this owner");
            window.location.href = '/client-secondary-owner/' + this.owner.id;
            if (complete && !this.completed) {
              this.completed = true;
            }
          }
          this.savingOwner = false;
        }).catch(error => {
          if (error.response.status == 403) {
            window.location = $themeConfig.app.suspendedAccountRedirect;
          }
        });
      } else {
        clientSecondaryOwnerService.createClientSecondaryOwner({
          clientId: this.$route.query.clientId,
          owner: this.owner
        }).then(response => {
          if (response.data.errors) {
            this.showErrorMessage("Something went wrong!");
          } else {
            this.showSuccessMessage("You have successfully created a new owner");
            this.owner.id = response.data.id;
            window.location.href = '/client-secondary-owner/' + this.owner.id;
          }
          this.savingOwner = false;
        }).catch(error => {
          if (error.response.status == 403) {
            window.location = $themeConfig.app.suspendedAccountRedirect;
          }
        });
      }
    },
    updateProgressListener(value) {
      this.owner.answers = value;
      this.saveOwnerProgress();
    },
    updateFilesListener(value) {
      this.files = value;
    },
    updateRiskListener(value) {
      this.ownerRisk = value;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
